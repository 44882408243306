<template>
  <div>
    <Report module="Locação" name="Locação por Produto" urlGet="/api/v1/rental/report/rent-product" :header="header"
      :filters="filters" />
  </div>
</template>

<script>

import Report from "@nixweb/nixloc-ui/src/component/report/Report";

export default {
  name: "ReportRentByCustomer",
  components: {
    Report
  },
  data() {
    return {
      header: [
        {
          title: "Dt Início",
          field: "dateStartStr",
          fieldOrderBy: "DateStart",
          show: true,
          styleHeader: "width:100px;",
          styleBody: "max-width: 90px;",
        },
        {
          title: "Dt Fim",
          field: "dateEndStr",
          fieldOrderBy: "DateEnd",
          show: true,
          styleHeader: "width:100px;",
          styleBody: "max-width: 90px;",
        },
        {
          title: "Dt Efetivação",
          field: "dateAcceptedStr",
          fieldOrderBy: "DateAccepted",
          show: false,
          styleHeader: "width:100px;",
          styleBody: "max-width: 90px;",
        },
        {
          title: "Número",
          field: "number",
          fieldOrderBy: "Number",
          show: true,
          initialOrderBy: true,
          styleHeader: "width:80px;",
          styleBody: "max-width: 80px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
        {
          title: "Identificação",
          field: "identification",
          fieldOrderBy: "Identification",
          show: false,
          styleHeader: "width:140px;",
          styleBody: "max-width: 120px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
        {
          title: "Consultor",
          field: "collaboratorName",
          fieldOrderBy: "CollaboratorName",
          show: false,
          styleHeader: "width:150px;",
          styleBody: "max-width: 120px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
        {
          title: "Cliente",
          field: "customerName",
          fieldOrderBy: "CustomerName",
          show: false,
          styleHeader: "width:250px;",
          styleBody: "max-width: 240px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
        {
          title: "Contato",
          field: "contactName",
          fieldOrderBy: "ContactName",
          show: false,
          styleHeader: "width:150px;",
          styleBody: "max-width: 120px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
        {
          title: "St Locação",
          field: "statusRentalName",
          fieldOrderBy: "StatusRentalName",
          show: false,
          styleHeader: "width:90px;",
          styleBody: "max-width: 80px;",
        },
        {
          title: "Tipo Produto",
          field: "typeProductName",
          fieldOrderBy: "TypeProductName",
          show: true,
          styleHeader: "width:150px;",
          styleBody: "max-width: 140px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
        {
          title: "Produto",
          field: "productName",
          fieldOrderBy: "ProductName",
          show: true,
          styleHeader: "width:350px;",
          styleBody: "max-width: 340px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
        {
          title: "Tipo de Locação",
          field: "typeRentName",
          fieldOrderBy: "TypeRentName",
          show: false,
          styleHeader: "width:130px;",
          styleBody: "max-width: 120px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
        {
          title: "R$ Unitário",
          field: "unitValueStr",
          fieldOrderBy: "UnitValue",
          show: true,
          styleHeader: "width:120px;",
          styleBody: "max-width: 120px;",
        },
        {
          title: "R$ Desconto",
          field: "unitDiscountStr",
          fieldOrderBy: "UnitDiscount",
          show: false,
          styleHeader: "width:120px;",
          styleBody: "max-width: 120px;",
        },
        {
          title: "R$ Custo",
          field: "unitCostStr",
          fieldOrderBy: "unitCost",
          show: false,
          styleHeader: "width:120px;",
          styleBody: "max-width: 120px;",
        },
        {
          title: "Qtd",
          field: "quantity",
          fieldOrderBy: "Quantity",
          show: true,
          styleHeader: "width:80px;",
          styleBody: "max-width: 70px;",
        },
        {
          title: "R$ Locação",
          field: "totalStr",
          fieldOrderBy: "Total",
          show: true,
          styleHeader: "width:120px;",
          styleBody: "max-width: 120px;",
        },
      ],
      filters: [
        {
          type: "date",
          title: "Dt Efetivação",
          field: "DateAccepted",
          value: "",
          required: false,
        },
        {
          type: "date",
          title: "Dt Inicio",
          field: "DateStart",
          value: "",
          required: true,
        },
        {
          type: "date",
          title: "Dt Fim",
          field: "DateEnd",
          value: "",
          required: true,
        },
        {
          type: "multiOptions",
          title: "Consultor",
          field: "CollaboratorName",
          url: "/api/v1/human-resources/collaborator/select-all",
          propsParams: { isConsultant: true },
          value: "",
        },
        {
          type: "multiOptions",
          title: "Cliente",
          field: "CustomerName",
          url: "/api/v1/crm/customer/select-all-without-document",
          propsParams: { isCustomer: true, isSupplier: false },
          value: "",
        },
        {
          type: "options",
          title: "St Locação",
          field: "StatusRentalName",
          options: [
            { text: "Orçamento", value: "Orçamento" },
            { text: "Aprovada", value: "Aprovada" },
            { text: "Reprovada", value: "Reprovada" },

          ],
          value: [],
        },
        {
          type: "options",
          title: "Tipo Produto",
          field: "TypeProductName",
          options: [
            { text: "Acessório", value: "Acessório" },
            { text: "Equipamento", value: "Equipamento" },
            { text: "Material", value: "Material" },
            { text: "Serviço", value: "Serviço" },
          ],
          value: [],
        },
        {
          type: "multiOptions",
          title: "Produto",
          field: "ProductName",
          url: "/api/v1/stock/product/select-all-with-typeProduct",
          value: "",
        },
        {
          type: "multiOptions",
          title: "Tipo de Locação",
          field: "TypeRentName",
          url: "/api/v1/rental/type-rent/select-all",
          value: "",
        },
      ],
    }
  }
}


</script>